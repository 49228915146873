import axios from "axios";


export const getGrids = async () => {
  //const url = "http://localhost:8092/sigeos/grids";
  const url = "https://backend-sigeos.herokuapp.com/sigeos/grids";
  return await axios.get(url);  
  //Materiais - 635b1b90e469cf99621ee27e
  //Servicos - 636136ece469cf9962281837
}

export const getGridData = async (gridId, issueKey) => {
  const url = "https://backend-sigeos.herokuapp.com/sigeos/grids/servico/"+gridId+"/"+issueKey;
  //const url = "http://localhost:8092/sigeos/grids/servico/"+gridId+"/"+issueKey;
  return await axios.get(url);
}


export const getListIssuesWithServicesOnGrids = async (inicio, fim) => {
  //serviço
  //const query = "resolved >= '"+inicio+" 00:00' AND resolved <= '"+fim+" 23:59' AND gridNumberColumn3 >= 0  and resolution = done  order by resolved ASC"
  //material
  //const query = "resolved >= '"+inicio+" 00:00' AND resolved <= '"+fim+" 23:59' AND gridNumberColumn8 > 0 and resolution = done  order by resolved ASC"
  //const url = "http://localhost:8092/sigeos/cloud/search/"+jql;
  const url = "https://backend-sigeos.herokuapp.com/sigeos/cloud/search/";
  //const url = "http://localhost:8092/sigeos/cloud/search/";
  
  const query = "status changed TO Fechado after '"+inicio+" 00:00' AND status changed TO Fechado before '"+fim+" 23:59' AND gridNumberColumn3 > 0 order by resolved ASC";
  const startAt = 0;
  const maxResults = 100;

  return await axios.post(url, {jql: query, startAt:startAt, maxResults:maxResults});
}

export const getListIssuesOnPeriod = async (inicio, fim) => {
  const url = "https://backend-sigeos.herokuapp.com/sigeos/cloud/search/";
  //const url = "http://localhost:8092/sigeos/cloud/search/";
  const startAt = 0;
  const maxResults = 100;
  const jql = "status changed TO Fechado after '"+inicio+" 00:00' AND status changed TO Fechado before '"+fim+" 23:59' order by resolved ASC";
  
  return await axios.post(url, {jql: jql, startAt:startAt, maxResults: maxResults});
}

export const getMaterialIssue = async (issueKey) => {
  const url = "https://backend-sigeos.herokuapp.com/sigeos/grids/material/635b1b90e469cf99621ee27e/"+issueKey;
  //const url = "http://localhost:8092/sigeos/grids/material/635b1b90e469cf99621ee27e/"+issueKey;
  
  return await axios.get(url);
}

export const getServicoIssue = async (issueKey) => {
  const url = "https://backend-sigeos.herokuapp.com/sigeos/grids/servico/636136ece469cf9962281837/"+issueKey;
  //const url = "http://localhost:8092/sigeos/grids/material/635b1b90e469cf99621ee27e/"+issueKey;
  
  return await axios.get(url);
}